.toolbox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "toolbox__left toolbox__centre toolbox__right";
  height: 73px;

  padding: 0 15px;
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: var(--quinary-foreground-colour);
  background-color: var(--tertiary-background-colour);

  .toolbox__left {
    grid-area: toolbox__left;
    place-self: center left;
    display: flex;
    flex-direction: row;
    align-items: center;

    .toolbox__drawer {
      display: flex;
      flex-direction: row;
      height: 35px;
      border-radius: 2px;
      background-color: var(--senary-background-colour);

      .toolbox__compartment {
        margin-top: 2px;
        margin-bottom: 2px;
        margin-left: 2px;
        width: 31px;
        height: 31px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: var(--tertiary-background-colour);
        color: var(--sidenav-background-colour);
        cursor: pointer;
      }

      .toolbox__compartment:last-child {
        margin-right: 2px;
      }
    }

    .button-enabled {
      background-color: #011e41 !important;
      color: white !important;
    }

    .flytomode-icon-wrapper {
      margin-left: 10px;
    }

    .icon-eye-icon {
      font-size: 26px;
      border-radius: 14px;
      border: 1px solid black;

      &.disabled {
        border: 1px solid gray;
        color: gray;
      }
    }
  }

  .toolbox_middle {
    grid-area: toolbox__centre;
    place-self: center center;
    align-items: center;
    display: flex;
    height: 26px;

    .paginationNavigationButton {
      min-width: 26px !important;
      height: 26px;
      font-size: 14px;
      padding: 10px;
      color: var(--nav-text-colour);

      &.disabled {
        color: gray;
      }
    }

    .paginationTextBox {
      display: flex;
      column-gap: 10px;
      height: 20px;
      padding: 0 15px;
      justify-content: stretch;
      align-items: center;
      font-size: 16px;

      .paginationNumberButton {
        min-width: 26px !important;
        height: 26px;
        padding: 0;
        color: var(--nav-text-colour);

        &.currentPage {
          color: var(--tertiary-background-colour);
          background-color: var(--nav-text-colour);
        }
      }

      .paginationDotsButton {
        min-width: 26px !important;
        height: 26px;
        padding: 0;
        color: var(--nav-text-colour);

        .paginationButton_Dotsicon {
          font-size: 3px;
        }
      }
    }
  }

  .toolbox__right {
    grid-area: toolbox__right;
    place-self: center right;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

    .selected_RW_Navigation,
    .active_RW_Navigation {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: inherit;
      border: 2px;
      border-style: solid;
      border-color: var(--quinary-foreground-colour);
      color: var(--nav-text-colour);
      margin-left: 10px;
      height: 30px;

      .RW_navigation-textBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100px;

        .RW_navigation-text {
          flex: 1;
          min-width: 0;
          .RW_navigation-text-ellipsis {
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 600;

            &.disabled {
              color: rgba(0, 0, 0, 0.26);
            }
          }
        }
      }

      .RW_navigation-button-case {
        border-width: 0 0 0 2px;
        border-style: solid;
        .RW_navigation-button {
          display: flex;
          justify-self: center;
          text-align: left;
          min-width: 32px;
          height: 100%;
        }
      }
    }

    .selected_RW_Navigation {
      background-color: var(--rw-selected-navigation-background-colour);
    }
  }
}

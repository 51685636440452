.slug {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--nav-text-colour);

  .slug__spacer {
    margin-left: 2px;
    margin-right: 2px;
  }

  .slug__name, .slug__icon {
    font-weight: 600;
  }

  .slug__icon {
    margin-left: 4px;
  }
}
.infobar {
  margin-left: 15px;
  margin-top: 15px;
  margin-right: 15px;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @import "./Slug.scss";

  .navigations {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    @import "./Navigations.scss";
  }
}

.sidenav {
  background-color: var(--sidenav-background-colour);
  transition: var(--colors-transition), width 0.5s;
  z-index: var(--sidenav-layer-zindex);
  width: 50px;
  display: flex;
  flex-direction: column;
  color: var(--sidenav-link-text-colour);
  border-right: 1px solid var(--sidenav-link-text-colour);

  .sidenav__content {
    display: flex;
    flex-direction: row;
    flex: 1;
    @import './Sections.scss';
    @import './ViewList.scss';
  }
  

  @import './Logo.scss';
  @import './Link.scss';
  @import './Signin.scss';
  @import './Signout.scss';
  @import './User.scss';
}

.sidenav.expanded {
  width: 264px;
  
  @import './expanded/LinkExpanded.scss';
  @import './expanded/LogoExpanded.scss';
  @import './expanded/UserExpanded.scss';
}
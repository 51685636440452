.pastnavigations,
.availablenavigations {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: inherit;

  .navigation-button {
    padding: 0 8px;
    margin-left: 20px;
    border: 2px;
    border-style: solid;
    border-color: var(--quinary-foreground-colour);
    height: 2.5rem;
    justify-content: space-between;
    color: var(--nav-text-colour);
    display: grid;

    .navigation-textBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 10rem;

      .navigation-text {
        flex: 1;
        min-width: 0;
        .navigation-text-ellipsis {
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .navigation-icon {
        display: flex;
        justify-self: center;
        text-align: left;
      }
    }
  }
}

.activefilterlist {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;

  /*scroll bar firefox styling*/
  scrollbar-width: thin;
  scrollbar-color: var(--senary-foreground-colour) var(--tertiary-background-colour);

  .activefilter {
    margin-right: 10px;
    max-width: 1000px;

    .activefilter-button {
      max-width: 1000px;
    }
  }
}

/*scroll bar non-firefox styling*/
.activefiltergrid ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.activefiltergrid ::-webkit-scrollbar-thumb {
  background: var(--senary-foreground-colour);
  border-radius: 10px;
}
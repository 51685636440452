body {
  display: block;
  min-height: 100vh;
  width: 100vw;
  margin: 0px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.insights {
  .progress-indicator-wrapper {
    text-align: center;
    padding: 25px;
  }

  .warning-text {
    color: red;
    padding: 10px 0;
  }
  #root {
    display: block;
    height: 100%;
    width: 100%;


    // Config
    @import "./config/ZIndices.scss";
    @import "./config/Animations.scss";
    @import "./config/Colours.scss";

    // Content
    @import "./components/App.scss";
  }
}

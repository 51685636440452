.name {
  font-size: 15pt;
  color: var(--nav-text-colour);

  .name__mode {
    color: var(--nav-icon-text-active-colour);
    font-weight: bold;
    font-size: larger;
  }
}

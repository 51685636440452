.link {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  color: var(--sidenav-link-text-colour);
  align-items: center;
  border-top: 1px solid var(--sidenav-link-text-colour);
  transition: var(--colors-transition);
  font-size: 12px;
  text-decoration: none;
  justify-content: space-around;

  .link__icon {
    font-size: 18px;
  }

  // TODO: Get the path from kiki
  .link__chevron {
    margin-left: auto;
    margin-right: 15px;
    color: var(--sidenav-link-text-hover-colour);
    transition: var(--colors-transition);
  }
}

.link:last-child {
  border-bottom: 1px solid var(--sidenav-link-text-colour);
  transition: var(--colors-transition);
}

.link.link--active, .link:hover {
  background-color: var(--sidenav-link-background-active-colour);
}

.link.link--active {
  color: var(--sidenav-link-text-active-colour);
}

.link:hover {
  color: var(--sidenav-link-text-hover-colour);
}
// SASS being a preprocessor doesn't recognize CSS variables in its functions(lighten, darken etc) so declaring variable here in SASS way which can be consumed by
// both SASS functions and inside declaration of CSS variable
$quantum-blue: #011E41;

.insights-app,
.default-colours {
  // Background Colours
  --primary-background-colour: #0f2c4e;
  --secondary-background-colour: #011e41;
  --tertiary-background-colour: #ffffff;
  --quarternary-background-colour: #cfcfcf;
  --quinary-background-colour: #f1efef;
  --senary-background-colour: #888888;

  // Foreground Colours
  --primary-foreground-colour: rgba(255, 255, 255, 0.6);
  --secondary-foreground-colour: #ffaa48;
  --tertiary-foreground-colour: #a4a4a4;
  --quarternary-foreground-colour: #ffffff;
  --quinary-foreground-colour: var(--secondary-background-colour);
  --senary-foreground-colour: #65788d;

  // Backgrounds
  --sidenav-background-colour: var(--primary-background-colour);

  --sidenav__logo-background-colour: var(--secondary-background-colour);
  --sidenav-link-background-active-colour: var(--secondary-background-colour);
  --themevariant--selected-background-colour: var(
    --secondary-background-colour
  );

  --nav-background-colour: var(--tertiary-background-colour);
  --footer-background-colour: var(--tertiary-background-colour);
  --nav-toggle-background-colour: var(--tertiary-background-colour);
  --appbody-background-colour: var(--quinary-background-colour);
  --rw-selected-navigation-background-colour: #6788b14d;

  --spacer-background-colour: var(--quarternary-background-colour);

  // Foregrounds
  --sidenav-link-text-colour: var(--primary-foreground-colour);

  --nav-icon-text-active-colour: var(--secondary-foreground-colour);
  --sidenav-link-text-active-colour: var(--secondary-foreground-colour);

  --nav-icon-text-colour: var(--tertiary-foreground-colour);
  --nav-toggle-icon-text-colour: var(--tertiary-foreground-colour);

  --sidenav-link-text-hover-colour: var(--quarternary-foreground-colour);

  --themechanger-foreground-colour: var(--quinary-foreground-colour);
  --nav-text-colour: var(--quinary-foreground-colour);
  --themevariant--selected-foreground-colour: var(--quarternary-foreground-colour);

  // Fugro Logo colours
  // using interpolation here to reference sass variable
  --fugro-logo-color-quantum-blue: #{$quantum-blue};;

  // Supporting colours as per Fugro Color palette
  --fugro-supporting-color-cosmic-03: #EE9A39;
  --fugro-supporting-color-cosmic-02: #F1CB4C;
}

@import "./DarkColours.scss";
@import "./LightColours.scss";

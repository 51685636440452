.navbar {
  height: 60px;
  display: flex;
  flex: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  z-index: var(--nav-layer-zindex);
  background-color: var(--nav-background-colour);
  @import '../../grid/Favorites.scss';

  .navbar__details {
    margin-left: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @import './Name.scss';
    @import './Spacer.scss';
  }
}
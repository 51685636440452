.signout {
  margin-top: auto;
  height: 50px;
  color: var(--sidenav-link-text-colour);
  transition: var(--colors-transition);
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;

  .signout__icon {
    font-size: 20px;
  }

  .signout__text {
    font-size: 12px;
  }
}
.filtergrid {
  display: grid;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  padding: 0px 20px 0px 20px;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: var(--tertiary-foreground-colour);
  border-radius: 3px 3px 0 0;
  background-color: var(--tertiary-background-colour);

  .filterselects {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: center;
    margin-top: 5px;
    padding-top: 5px;

    .fugro-current-cycle-selector {
      white-space: nowrap;
    }

    .MuiSwitch-colorSecondary.Mui-checked {
      color: var(--fugro-supporting-color-cosmic-03);
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: var(--fugro-supporting-color-cosmic-02);
    }

    /*scroll bar firefox styling*/
    scrollbar-width: thin;
    scrollbar-color: var(--senary-foreground-colour)
      var(--tertiary-background-colour);

    .pagetopFilter {
      min-width: 200px;
      max-width: max-content;
      height: 40px;

      .pagetopFilter-selected {
        width: 200px;
        background: white;
      }
    }
  }

  /*scroll bar non-firefox styling*/
  .filterselects::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .filterselects::-webkit-scrollbar-thumb {
    background: var(--senary-foreground-colour);
    border-radius: 10px;
  }
}

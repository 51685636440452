.favorites {
  display: flex;
  flex-direction: row;
  align-items: center;

  .favorite-button {
    min-width: 0;
    padding-right: 10px;
    color: var(--quarternary-background-colour);
    height: fit-content;

    .favorite-icon {
      width: 100%;
      display: flex;
      justify-items: center;
      text-align: left;

      &-add {
        font-size: 150%;
      }

      &-edit {
        font-size: 125%;
      }    
    }
  }

  .MuiAutocomplete-clearIndicator {
    visibility: visible;
  }

  &:hover {
    color: var(--nav-text-colour);
    background-color: transparent;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 10px;
  height: 20px;
  box-shadow: 0px -3px 3px #00000029;
  background-color: var(--footer-background-colour);
  z-index: var(--nav-layer-zindex);

  .footer__copywrite {
    margin-top: auto;
  }
}
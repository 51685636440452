.main-content {
  display: flex;
  flex: 1;
  flex-direction: row;

  .grid-selection-text {
    margin: 20px;
    font-size: 1.5rem;
  }

  .app-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--appbody-background-colour);

    .app-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      .gridparent {
        display: flex;
        flex-direction: column;
        flex: 1;

        .ag-theme-balham .ag-row-selected {
          background-color: lighten($quantum-blue, 70%);
        }
        .ag-theme-balham .ag-row-hover {
          background-color: lighten($quantum-blue, 50%);
        }

        @import "./grid/Infobar.scss";
        @import "./layout/filters/ActiveFilter.scss";
        @import "./layout/filters/FilterSelects.scss";
        @import "./grid/Toolbox.scss";
        @import "./grid/CircularProgress.scss";
      }
    }
  }
}

// Layout
@import "./layout/Footer.scss";
@import "./layout/Sidebar.scss";
@import "./layout/navbar/Navbar.scss";
@import "./layout/sidenav/Sidenav.scss";
